	/*  Wizard */
	jQuery(function ($) {
		"use strict";
		// Chose here which method to send the email, available:
		// Phpmaimer text/html > phpmailer/reservation_phpmailer.php
		// Phpmaimer text/html SMPT > phpmailer/reservation_phpmailer_smtp.php
		// PHPmailer with html template > phpmailer/reservation_phpmailer_template.php
		// PHPmailer with html template SMTP> phpmailer/reservation_phpmailer_template_smtp.php
		// $('form#wrapped').attr('action', 'phpmailer/reservation_phpmailer_template.php');
	});
